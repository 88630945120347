<template id="WorkCard">

    <div>

        <form v-on:submit.prevent="addProductLine">
            <div class="row mb-2">
                <div class="col-2">
                    <label class="form-label">Barcode</label>
                    <input id="bar_code" name="bar_code" type="text" class="form-control" placeholder="scan barcode" aria-label="Barcode">
                </div>
                <div class="col-2">
                    <label class="form-label">Produkt</label>
                    <select id="product_dropdown" name="product_dropdown" v-model="selectedProduct.sku" class="form-control">
                        <option v-for="(product, index) in getProducts" :key="product">
                            {{ product.sku }}
                        </option>
                    </select>
                </div>
                <div class="col-3">
                    <label class="form-label">Beskrivelse</label>
                    <input id="bar_code" name="bar_code" type="text" class="form-control" v-model="selectedProduct.description" placeholder="..." aria-label="Barcode">
                </div>
                <div class="col-1">
                    <label class="form-label">Antal</label>
                    <input id="quantity" name="quantity" type="text" class="form-control" v-model="selectedProduct.pivot.quantity" placeholder="" aria-label="quantity">
                </div>
                <div class="col-1">
                    <label class="form-label">Rabat</label>
                    <input id="bar_code" name="bar_code" type="text" class="form-control" v-model="selectedProduct.pivot.discount_percentage" placeholder="%" aria-label="Barcode">
                </div>
                <div class="col-2">
                    <label class="form-label">Pris</label>
                    <input id="bar_code" name="bar_code" type="text" class="form-control" v-model="selectedProduct.sales_price" placeholder="kr." aria-label="Barcode">
                </div>
                <div class="col-1">
                    <label class="form-label">Tilføj</label>
                    <button class="btn bg-gradient-dark d-block w-kit-available" type="submit">
                        <i class="fa-solid fa-plus"></i>
                    </button>
                </div>
            </div>
        </form>

        <div class="row mt-3">
            <div class="col-12 col-md-12 col-xl-12 mt-md-0 mt-4">
                <div class="h-200">
                    <div class="form-control card-body no-padding">
                        <div class="table-responsive p-0">
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            Produkt
                                        </th>
                                        <th class="text-end text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 p-1">
                                            Antal
                                        </th>
                                        <th class="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 p-1">
                                            Enhed
                                        </th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            Rabat
                                        </th>
                                        <th class="text-end text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            Stk. pris
                                        </th>
                                        <th class="text-end text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            Pris
                                        </th>
                                        <th class="text-end text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr v-for="(product, index) in productLines" :key="product.sku">
                                        <!-- product -->
                                        <td>
                                            <div class="d-flex px-3 py-1">
                                                <div>
                                                    <img :src="product.image" class="avatar me-3" alt="image">
                                                </div>
                                                <div class="d-flex flex-column justify-content-center">
                                                    <h6 class="mb-0 text-sm">
    <!--                                                    {% for meta in product.getRelation('meta') %}-->
    <!--                                                        {% if meta.i18n == session.lang %}-->
    <!--                                                            {{ meta.title }}-->
    <!--                                                        {% endif %}-->
    <!--                                                    {% endfor %}-->
                                                        {{ product.description }}
                                                    </h6>
                                                    <p class="text-sm font-weight-bold text-secondary mb-0">
                                                        sku: <span class="text-success">{{ product.sku }}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </td>
                                        <!-- quantity -->
                                        <td>
                                            <p class="text-sm text-end font-weight-bold mb-0">
                                                {{ product.pivot.quantity }}
                                            </p>
                                        </td>
                                        <!-- unit -->
                                        <td class="align-middle text-start text-sm">
                                            <p class="text-sm font-weight-bold mb-0">
                                                stk.
                                            </p>
                                        </td>
                                        <!-- discount -->
                                        <td class="align-middle text-center">
                                            <template v-if="product.pivot.discount_percentage">
                                                {{ product.pivot.discount_percentage }}%
                                            </template>
                                        </td>
                                        <!-- price pr. unit -->
                                        <td class="text-end">
                                            <p class="text-sm text-end font-weight-bold mb-0">
                                                {{ $filter.price(product.sales_price) }}
                                            </p>
                                        </td>
                                        <!-- price -->
                                        <td class="text-end">
                                            <p class="text-sm text-end font-weight-bold mb-0">
                                                <template v-if="product.pivot.discount_percentage">
                                                    {{ $filter.price(((product.sales_price - (product.sales_price / 100 * product.pivot.discount_percentage)) * product.pivot.quantity)) }}
                                                </template>
                                                <template v-else>
                                                    {{ $filter.price(product.sales_price * product.pivot.quantity) }}
                                                </template>
                                            </p>
                                        </td>
                                        <!-- actions -->
                                        <td class="text-end">
                                            <div class="px-3">
                                                <a href="javascript:;" class="mx-3" data-bs-toggle="tooltip" data-bs-original-title="Edit product">
                                                    <i class="fa-solid fa-pen-to-square text-secondary"></i>
                                                </a>
                                                <button class="no-border no-background" data-bs-toggle="tooltip" data-bs-original-title="Delete product" @click="deleteProductLine(index)">
                                                    <i class="fas fa-trash text-secondary"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-center">

<!--            <router-link class="btn btn-lg bg-gradient-nexus btn-rounded mt-4 mb-0" to="https://www.portfolio.tune-trafikskole.dk/dashboard/case/11">-->
<!--                OK-->
<!--            </router-link>-->

            <button @click="saveProductLines()" class="btn btn-lg bg-gradient-nexus btn-rounded mt-4 mb-0">
                OK
            </button>

            <button @click="closeModal()" class="btn btn-lg btn-outline-dark btn-rounded ms-4 mt-4 mb-0">
                annullere
            </button>

        </div>

    </div>

</template>

<script>

    // importing Vuex maps
    import { mapActions, mapGetters } from "vuex";

    // import Router
    import router from ">/router";

    export default {

        name: "WorkCard",
        components: {

            // ...

        },
        created() {

            // ...

        },
        mounted() {

            this.populateProductLines(this.token)
            this.populateProducts(this.token)

        },
        data() {
            return {

                selectedProduct: {
                    image: 'https://www.portfolio.tune-trafikskole.dk/public/assets/img_placeholder_800x800.svg',
                    sku: '',
                    description: '',
                    sales_price: null,
                    pivot: {
                        quantity: null,
                        discount_percentage: null,
                    },
                },

                productLines: []

            }
        },
        computed: {

            ...mapGetters({

                token:              'auth/authenticated',
                getProductLines:    'order/getProductLines',
                getProducts:        'products/getProducts'
            }),

        },
        methods: {

            ...mapActions({

                fetchProductLines:  'order/fetchProductLines',
                updateProductLines: 'order/updateProductLines',
                fetchProducts:      'products/fetchProducts'
            }),

            populateProductLines(token) {

                this.fetchProductLines(token)

                    .then(() => {

                        this.productLines = this.getProductLines

                        if (process.env.NODE_ENV === 'development') {

                            console.log("Product-lines state populated")

                        }

                    })

                    .catch(() => {

                        console.log("Fetching Product-lines (catch) failed")

                    })

            },

            populateProducts(token) {

                this.fetchProducts(token)

                    .then(() => {

                        if (process.env.NODE_ENV === 'development') {

                            console.log("Product state populated")

                        }

                    })

                    .catch(() => {

                        console.log("Fetching Product (catch) failed")

                    })

            },

            addProductLine: function() {

                let product = {

                    image: 'https://www.portfolio.tune-trafikskole.dk/public/assets/img_placeholder_800x800.svg',
                    sku: this.selectedProduct.sku,
                    description: this.selectedProduct.description,
                    sales_price: this.selectedProduct.sales_price,
                    pivot: {
                        quantity: this.selectedProduct.pivot.quantity,
                        discount_percentage: this.selectedProduct.pivot.discount_percentage,
                    }
                }

                this.productLines.push(product);

                this.selectedProduct.sku                        = null
                this.selectedProduct.description                = null
                this.selectedProduct.sales_price                = null
                this.selectedProduct.pivot.quantity             = null
                this.selectedProduct.pivot.discount_percentage  = null

            },

            deleteProductLine: function(product) {

                this.productLines.splice(this.productLines.indexOf(product), 1);
            },

            saveProductLines: function () {

                const payload = {

                    token:      this.token,
                    products:   this.productLines,
                };

                this.updateProductLines(payload)

                    .then(() => {

                        this.productLines = this.getProductLines

                        if (process.env.NODE_ENV === 'development') {

                            console.log("Product-lines state populated")

                        }

                        location.reload(); // Reloads the current page

                    })

                    .catch(() => {

                        console.log("Fetching Product-lines (catch) failed")

                    })

            },

            closeModal: function () {

                $('#add-products').modal('hide')
            }
        }
    }

</script>

<style scoped lang="scss">

    // ...

</style>
